<!-- 贷款计算器 -->
<template>
  <div>
    <van-nav-bar
        title="贷款类型"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
  </div>
  <div style="padding: 3%;">
    <van-row justify="space-around">
      <van-col span="10">
        <van-button type="primary" size="large" to="xyd">信用贷</van-button>
      </van-col>
      <van-col span="10">
        <van-button type="primary" size="large" to="jyd">经营贷</van-button>
      </van-col>
    </van-row>
  </div>
  <div style="padding: 3%;">
    <van-row justify="space-around">
      <van-col span="10">
        <van-button type="primary" size="large" to="spd">税票贷</van-button>
      </van-col>
      <van-col span="10">
        <van-button type="primary" size="large" to="gjjd">公积金贷</van-button>
      </van-col>
    </van-row>
  </div>
  <div style="padding: 3%;">
    <van-row justify="space-around">
      <van-col span="10">
        <van-button type="primary" size="large" to="cdd">车抵贷</van-button>
      </van-col>
      <van-col span="10">
        <van-button type="primary" size="large" to="fdd">房抵贷</van-button>
      </van-col>
    </van-row>
  </div>
</template>
<script>

import {Button, Row, Col, NavBar} from 'vant';


export default {
  name: "index",

  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    onClickLeft() {
      window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index/vwif?storeId=2395089355"
    },
  }
};
</script>

<style>
body {
  font-family:"Microsoft YaHei","Arial","Helvetica","sans-serif","宋体";
}
</style>
